$font-family-serif: "Noto Serif", Lucida Bright, Lucida Fax, Palatino, "Palatino Linotype", Palladio, "URW Palladio", serif;
$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-base: $font-family-serif !default;

$font-family-headlines: "Handlee";
$font-family-navbar: "Handlee";
$font-size-navbar: 150%;

$text-color: rgb(44, 22, 0);
$breadcrumb-background:  rgba(255, 204, 153, 0.0);
$body-background:  rgba(255, 255, 255, 0.3);
$nav-background: rgba(125, 191, 198, 0.8);     /* ffcc99 */
//$shop-background: rgba(255, 255, 20, 0.2);
$shop-background: rgba(255, 204, 153, 0.5);
$orange:  rgba(255, 204, 153, 1.0);
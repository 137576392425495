@include font-face('Noto Serif', /fonts/noto-serif-v7-latin-ext_latin-regular, 400);
@include font-face('Amita', /fonts/amita-v4-latin-ext_latin-regular, 400);
@include font-face('Handlee', /fonts/handlee-v7-latin-regular, 400);

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $font-family-headlines;
    text-align: center;
    margin-bottom: 1em;
    -moz-hyphens: auto;
    -o-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    color: #28575b;
}

.h2, h2 {
    margin-top: 2rem;
}

.navbar {
    padding-top: 0;
    padding-bottom: 0;
}

.bg-orange {
    background-color: $nav-background !important;
}

#navbarNavDropdown {
    font-family: $font-family-navbar;
    font-size: $font-size-navbar;
    color: #28575b;
}

#navbarNavDropdown li.nav-item {
    margin-right: 2rem;
    padding-top: 0.5rem;
}

#navbarNavDropdown .dropdown-item, .active {
    color: #28575b;
}

#navbarNavDropdown .nav-link, .nav-link.show, .nav-link.active {
    color: #28575b;
}

.navbar-flags {
    padding-top: -0.1rem;
    padding-left: 10px;
}

.navbar-flags img {
    height: 1.5rem;
}

.nav-link {
    font-weight: 200;
}

.nav-link.active {
    font-weight: 900;
}

.breadcrumb {
    padding: 0;
    /* position: absolute;
    width: 100%; */
    padding-left: 0px;
    /* padding-left: 4rem; */
    background-color: $breadcrumb-background !important;
}

.breadcrumb .items {
    position: relative;
    /* left: 1rem; */ 
    font-size: small;
}

.breadcrumb .item {
    margin-left: 0;
    margin-right: 0.2rem; 
}

.back-to-top {
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    display:none;
}

.imgproc {  
    margin-top: -2rem !important; 
}

.rightblock {
    text-align: right;
}

div#copyright {
    padding-bottom: 1rem;
}

/* Teaserboxen Allgemein */
.card-deck {
    margin-left: auto;
    margin-right: auto;   
}

.card.list-common {
    min-width: 300px;
    max-width: 300px;
    margin-bottom: 2rem;
}

.list-common img.preview {
    object-fit: contain;  /* Do not scale the image */
    object-position: top; /* Center the image within the element */
    height: 298px;
    width: 298px;
    margin-bottom: 0.5rem;   
    margin-top: 0.5rem;
}

.list-common .card-title {
    height: 1rem;
}

.list-common .card-body {
    text-align: justify;
    margin-bottom: 1rem;
}

.list-common .card-body .btn {
    vertical-align: bottom;
    position: absolute;
    bottom: 0.25rem;
}

div.centerblock {
    margin: auto;
    text-align: center;
}

.btn-warning {
    background-color: $nav-background !important;
    border-color: $nav-background !important; 
    font-family: $font-family-headlines;
    font-size: 125%;   
}

div.warning {
    color: red;
}

div.center {
    text-align: center;
}

/* Figure and thumbnail related stuff */

figure {
    display: inline-block;
}

figure.einzelbild {
    display: inline-block;
    padding: 10px;
    overflow: hidden;
    margin: 5px 8px 5px 0;
    box-shadow: 0 0 8px #666;
    padding: 4px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
}

figure.einzelbild figcaption {
    text-align: center;
    margin-top: 0.4rem;
    margin-bottom: -0.65rem;
    font-size: small;
}

figure.gallery.einzelbild figcaption {
    text-align: center;
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
    font-size: small;
}

figure.einzelbild img {
    margin-right: auto;
    margin-left: auto;
    display: block;
    object-fit: cover;
}
  
figure.mainimage {
    text-align: center;
}

.thumbnail {
    height: 9em;
    float: left;
    overflow: hidden;
    margin: 5px 8px 5px 0;
    box-shadow: 0 0 8px #666;
}

.thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Shop */
.card.shop {
    margin-left: auto;
    margin-right: auto;   
    min-width: 300px;
    max-width: 400px;
    margin-bottom: 3rem;
    box-shadow: 0 0 8px #666;
    background-color: $shop-background;
    margin-top: 2rem;
}

.card.shop .card-body {
    padding-bottom: 0.5rem;
}

.shop .card-title {
    font-size: 150%;
    display: none;
}

.card.shop ul {
    list-style-type: none;
    padding-left: 0;
}

.shop .pattern-title {
    font-size: 150%;
}

.shop .pattern-availability {
    margin-top: 0.5rem;
}

.shop .bottomline {
    margin-bottom: 1rem;    
}

.shop .pattern-data {
    display: table;
    margin-left: auto;
    margin-right: auto;
}

.shop .pattern-data .key {
    font-weight: bold;
    float: left;
    text-align: left;
}

.shop .pattern-data .value {
    margin-bottom: -0.8rem;
    float: left;
    text-align: left;
    max-width: 250px;
}

.shop .pattern-data .value ul {
    list-style-type: disc;
    margin-left: 1.5rem;
}

.shop .disclaimer {
    font-size: 75%;
}

.shop .btn {
    margin-bottom: 1rem;
}

div#all {
    background-color: $body-background;
    color: $text-color;
}

/* Pattern teaser image */
figure.patternteaser {  
    display: inline-block;
    padding: 10px;
    overflow: hidden;
    margin: 5px 8px 5px 0;
    box-shadow: 0 0 8px #666;
    padding: 4px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 1rem;
    margin-left: 11px;
}

@media screen and (max-width:575px){
    figure.patternteaser {
        max-width: 310px;
    }
    figure.patternteaser img {
        width: 300px;
    }

    figure.einzelbild {
        max-width: 310px;
    }
    figure.einzelbild img, a {
        width: 300px;
    }
}

.patternteaser figcaption {
    font-size: small;
    //padding-top: 0.25rem;
    padding-bottom: 0.75rem;
}

div.guestgallery {
    margin-top: 4rem;
}

.guestgallery figure {
    margin-bottom: 2rem;
}

.card.news .card-image, .card-body, .card-header {
    background-color: $orange;
}

.card.news .card-image {
    height: 280px;
    /* background-color: #ddd; */
    position: relative;
}

.card.news .card-image img {
    max-height: 100%;  
    max-width: 100%; 
    width: auto;
    height: auto;
    position: absolute;  
    top: 0;  
    bottom: 0;  
    left: 0;  
    right: 0;  
    margin: auto;
}

div.legal h2 {
    text-align: left;
    font-family: $font-family-sans-serif;
    font-size: x-large;
    
}

div.legal h3 {
    text-align: left;
    font-family: $font-family-sans-serif;
    font-size: medium;
}

div.left {
    text-align: left;
}